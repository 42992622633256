import { navigate, useStaticQuery, graphql } from 'gatsby';
import React, { useEffect, useState, useContext, createContext } from 'react';
import { Cookies } from 'react-cookie-consent';
const { townNamesMatch } = require('../util/util');
import LoadingMessage from './generic/LoadingMessage';
import { captureException, setContext } from '@sentry/gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { SendFacebookConversionsEvent } from '../services/FacebookConversionsApiService';
import FacebookPixelEvents from '../services/FacebookPixelEvents';
import useScreenSize from '../hooks/useScreenSize';
import AddressContext from '../context/AddressContext';
import OneTouchSwitchContext from '../context/OneTouchSwitchContext';
let deBounce;
import { OtsCookie } from '../data/Cookies';
import {
  getProductAvailability,
  OrderJourneyTypes,
  getDemographicData,
} from '../services/OrderService';
import dataLayer from './DataLayer';
// For Cookies
const cookieName = 'litfibre-address';
const regionalPromoCookie = 'litfibre-promo';

const PostcodeSearch = ({
  selectedAddress,
  setSelectedAddress,
  inputDisplayValue,
  setInputDisplayValue,
  placeholder,
  textButton,
  isSmall,
  orderPath = '/secure-order/contact-form-page/',
  registerInterestPath = '/secure-order/contact-form-page/',
  isNav,
  closeMenu,
  isOnContactFormPage,
  setAddressSubmitted,
  addressSubmitted,
}) => {
  const screenSize = useScreenSize();
  const data = dataQuery();
  let town = undefined;
  const { setCurrentAddress } = useContext(AddressContext);
  const { clearOtsData, otsData } = useContext(OneTouchSwitchContext);
  data.landingPages.edges.forEach((townNode) => {
    if (
      selectedAddress &&
      (townNamesMatch(townNode.node.townName, selectedAddress.postalTown) ||
        townNamesMatch(townNode.node.townName, selectedAddress.addressLine2) ||
        townNamesMatch(townNode.node.townName, selectedAddress.addressLine3))
    ) {
      town = townNode.node.slug;
    }
  });

  useEffect(() => {
    // Executed on Mount
    const cookie = Cookies.get(cookieName);

    if (cookie) {
      const storedAddress = JSON.parse(atob(cookie));
      setSelectedAddress(storedAddress);
      setInputDisplayValue(
        `${storedAddress.addressLine1}, ${
          storedAddress.addressLine2 ? `${storedAddress.addressLine2},` : ''
        } ${storedAddress.postcode}`,
      );
    } else if (inputDisplayValue) {
      deBounceRun(inputDisplayValue);
    }

    // Executed On Dismount
    return () => {
      // As this sometimes seems to retain the script on the page, we want to ensure it's removed.
    };
  }, []);

  const [addressResult, setAddressResult] = useState([]);
  const [dropdownMessage, setDropdownMessage] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const deBounceRun = (value) => {
    deBounce = undefined;
    setSelectedAddress(undefined);
    setError(undefined);
    setDropdownMessage(undefined);
    setAddressResult([]);

    let postcode = value.replace(/\s+/g, '');
    const validatedPostcode = validatePostCode(postcode);

    if (validatedPostcode.isValid) {
      postcode = validatedPostcode.postcode;
      grecaptcha.ready(() => {
        var action = `/address/postcode/`;
        grecaptcha
          .execute(process.env.GATSBY_RECAPTCHA_SECRET_KEY, {
            action: action,
          })
          .then((token) => {
            setLoading(true);
            fetch(`${process.env.GATSBY_API_ADDRESS}${action}${value}`, {
              headers: {
                'Captcha-Token': `${token}`,
                ApiKey: `${process.env.GATSBY_ADDRESS_API_KEY}`,
              },
            })
              .then((response) => response.json())
              .then((result) => {
                const hasData = result.length > 0;

                if (!hasData) setDropdownMessage('No Properties found');
                else setAddressResult(result);
              })
              .catch((error) => {
                captureException(error);
                if (process.env.NODE_ENV === 'development')
                  console.error(error);
                setError('Oops! Something went wrong');
              })
              .finally(() => setLoading(false));
          });
      });
    } else {
      if (!validatedPostcode.isValid)
        setDropdownMessage('Please enter a valid Postcode');
      else setDropdownMessage('No Properties found');
    }
  };

  const onChange = (event) => {
    // Blocks the user from just deleting the address. They will need to select 'change address' if they have already selected an address.
    if (selectedAddress !== undefined) return;

    const {
      target: { value },
    } = event;

    setInputDisplayValue(value.toUpperCase());
    setDropdownMessage(undefined);
    setAddressResult([]);

    if (deBounce !== undefined) {
      clearTimeout(deBounce);
    }

    deBounce = setTimeout(() => {
      deBounceRun(value);
    }, 700);
  };

  const onSelect = (e, uprn) => {
    const result = addressResult.find((d) => d.uprn === uprn);

    // Designed to ensure that when it changes it does not error if it cannot find the value
    // This is mainly for the "Select Address option"
    if (result) {
      setSelectedAddress({ ...result, orderable: false });
      setInputDisplayValue(
        `${result.addressLine1},${
          result.addressLine2 ? ` ${result.addressLine2},` : ''
        } ${result.postcode}`,
      );
    }
  };

  useEffect(() => {
    setCurrentAddress(selectedAddress);
  }, [setCurrentAddress, selectedAddress]);

  const onSubmit = () => {
    if (isNav) {
      closeMenu();
    }
    if (selectedAddress) {
      if (location && !location.href.includes('internal')) {
        let facebookEventId = '';

        if (typeof self !== 'undefined') {
          facebookEventId = self.crypto.randomUUID();
        }
        SendFacebookConversionsEvent({
          postcode: selectedAddress.postcode,
          eventName: 'FindLocation',
          city: selectedAddress.postalTown,
          county: selectedAddress.county,
          eventId: facebookEventId,
          contentName: 'Postcode Search',
        });
        FacebookPixelEvents.track(
          'FindLocation',
          {
            currency: 'GBP',
            delivery_category: 'home_delivery',
            content_category: 'product',
            content_name: 'Postcode Search',
          },
          facebookEventId,
        );
      }
      getProductAvailability(selectedAddress?.uprn, OrderJourneyTypes.public)
        .then((response) => response.json())
        .then(async (result) => {
          selectedAddress.orderable =
            result.content?.availableProducts?.litFibre?.length > 0 ||
            result.content?.availableProducts?.cityFibre?.length > 0;
          selectedAddress.litFibreProducts =
            result.content?.availableProducts?.litFibre?.length > 0
              ? result.content.availableProducts.litFibre
              : [];
          selectedAddress.cityFibreProducts =
            result.content?.availableProducts?.cityFibre?.length > 0
              ? result.content.availableProducts.cityFibre
              : [];
          setSelectedAddress(selectedAddress);

          if (selectedAddress?.orderable) {
            dataLayer({ event: 'uprn_orderable' });
            dataLayer({
              event: 'lookup_town',
              eventData: {
                town: selectedAddress.postalTown,
              },
            });
            dataLayer({
              event: 'lookup_uprn',
              eventData: { uprn: selectedAddress.uprn },
            });
            dataLayer({
              event: 'lookup_postcode',
              eventData: { postcode: selectedAddress.postcode },
            });
            await fetch(
              `${process.env.GATSBY_ORDER_API_ADDRESS}catalog/promotion/${selectedAddress.uprn}`,
              {
                headers: {
                  ApiKey: process.env.GATSBY_ORDER_API_KEY,
                },
              },
            )
              .then((response) => response.text())
              .then((result) => {
                if (result)
                  Cookies.set(regionalPromoCookie, btoa(result), {
                    secure: true,
                  });
              })
              .catch((e) => captureException(e));
          }

          !selectedAddress.orderable &&
            dataLayer({ event: 'uprn_unorderable' });

          const json = JSON.stringify(selectedAddress);

          Cookies.set(cookieName, btoa(json), {
            secure: true,
          });

          if (selectedAddress?.orderable && !isOnContactFormPage) {
            navigate(orderPath, {
              state: {
                address: selectedAddress,
                fromPostcodeCheck: true,
              },
            });
          } else if (!selectedAddress?.orderable && !isOnContactFormPage) {
            navigate(registerInterestPath, {
              state: {
                address: selectedAddress,
                inBuildTown: selectedAddress.postalTown,
                fromPostcodeCheck: true,
              },
            });
          } else if (isOnContactFormPage) {
            setAddressSubmitted(true);
          }
        })
        .catch((error) => {
          captureException(error);
          if (process.env.NODE_ENV === 'development') console.error(error);
          else console.log('An Error Occured Processing the Request');
        });

      getDemographicData(selectedAddress?.uprn, OrderJourneyTypes.public)
        .then((response) => response.json())
        .then((result) => {
          if (result?.success) {
            dataLayer({
              event: 'acorn_type',
              eventData: {
                type: result.content.demType,
              },
            });
            dataLayer({
              event: 'acorn_category',
              eventData: {
                category: result.content.demCategory,
              },
            });
            dataLayer({
              event: 'acorn_group',
              eventData: {
                group: result.content.demGroup,
              },
            });
          }
        })
        .catch((error) => {
          captureException(error);
          if (process.env.NODE_ENV === 'development') console.error(error);
          else console.log('An Error Occured Processing the Request');
        });
    }
  };

  const onChangeAddress = () => {
    if (addressResult.length === 0 && selectedAddress) {
      deBounceRun(selectedAddress.postcode);
    }

    setInputDisplayValue(selectedAddress.postcode);
    setSelectedAddress(undefined);
    isOnContactFormPage && setAddressSubmitted(false);

    Cookies.remove(cookieName);
    Cookies.remove(regionalPromoCookie);
    Cookies.remove(OtsCookie);

    otsData && clearOtsData();
  };

  const parseAddressNumberOrName = (address) => {
    const key = address.singleLineAddress.split(' ')[0];
    const numberSubstr = key.match(/[0-9]+/);
    // Regex allows a house number like '123B' to be treated as '123'
    return numberSubstr ? numberSubstr[0] : key;
  };

  const compareAddresses = (a, b) => {
    // Get first 'word'
    const aKey = parseAddressNumberOrName(a);
    const bKey = parseAddressNumberOrName(b);

    // Neither address has a house number, compare as strings
    if (isNaN(aKey) && isNaN(bKey)) return aKey.localeCompare(bKey);
    // Only B has a house number, it comes first
    else if (isNaN(aKey)) return 1;
    // Only A has a house number, it comes first
    else if (isNaN(bKey)) return -1;
    // Both have house numbers, compare as numbers
    else {
      return parseInt(aKey) - parseInt(bKey);
    }
  };

  const visibility = selectedAddress ? {} : { display: 'none' };

  return (
    <form
      className="postcode--search"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <input
        type="text"
        placeholder={placeholder}
        value={inputDisplayValue}
        onChange={(e) => onChange(e)}
        required
      />
      <button
        className="button"
        type="button"
        onClick={onSubmit}
        disabled={selectedAddress === undefined}
        title="Search"
        aria-label="Search"
      >
        {!isOnContactFormPage ? (
          textButton ? (
            textButton
          ) : screenSize.width >= 800 ? (
            'Search'
          ) : (
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          )
        ) : !addressSubmitted ? (
          'Submit'
        ) : (
          'Submitted'
        )}
      </button>
      {addressResult.length > 0 && selectedAddress === undefined && (
        <div className="postcode-results">
          {addressResult.sort(compareAddresses).map((address) => (
            <div
              key={address.uprn}
              value={address.uprn}
              onClick={(e) => onSelect(e, address.uprn)}
            >
              {`${address.addressLine1},${
                address.addressLine2 ? ` ${address.addressLine2},` : ''
              }${address.addressLine3 ? ` ${address.addressLine3},` : ''} ${
                address.postalTown
              }`}
            </div>
          ))}
        </div>
      )}
      {dropdownMessage != undefined && (
        <div className="postcode-no-results">
          <div key="no-results" value={dropdownMessage}>
            {dropdownMessage}
          </div>
        </div>
      )}
      {loading && (
        <div className="postcode-message">
          <div>
            <LoadingMessage text="Searching" />
          </div>
        </div>
      )}
      {error && (
        <div className="postcode-message">
          <div>
            <strong>{error}</strong>
            <p>Please check your postcode and try again</p>
          </div>
        </div>
      )}
      {!isSmall && (
        <div className="changeAddress" style={visibility}>
          <a onClick={onChangeAddress} className="clickable">
            Change address
          </a>
        </div>
      )}
    </form>
  );
};

export default PostcodeSearch;

const dataQuery = () =>
  useStaticQuery(graphql`
    {
      landingPages: allDatoCmsTownLandingPageModel {
        edges {
          node {
            slug
            townName
          }
        }
      }
    }
  `);

const validatePostCode = (postcode) => {
  let result = null;

  const p = postcode.replace(' ', '').toUpperCase();

  // AN NAA
  if (/^([A-Z]{1})([0-9]{2})([A-Z]{2})/.test(p))
    result = `${p[0]}${p[1]} ${p[2]}${p[3]}${p[4]}`;
  // ANN NAA
  else if (/^([A-Z]{1})([0-9]{3})([A-Z]{2})/.test(p))
    result = `${p[0]}${p[1]}${p[2]} ${p[3]}${p[4]}${p[5]}`;
  // AAN NAA
  else if (/^([A-Z]{2})([0-9]{2})([A-Z]{2})/.test(p))
    result = `${p[0]}${p[1]}${p[2]} ${p[3]}${p[4]}${p[5]}`;
  // AANN NAA
  else if (/^([A-Z]{2})([0-9]{3})([A-Z]{2})/.test(p))
    result = `${p[0]}${p[1]}${p[2]}${p[3]} ${p[4]}${p[5]}${p[6]}`;
  // ANA NAA
  else if (/^([A-Z]{1})([0-9]{1})([A-Z]{1})([0-9]{1})([A-Z]{2})/.test(p))
    result = `${p[0]}${p[1]}${p[2]} ${p[3]}${p[4]}${p[5]}`;
  // AANA NAA
  else if (/^([A-Z]{2})([0-9]{1})([A-Z]{1})([0-9]{1})([A-Z]{2})/.test(p))
    result = `${p[0]}${p[1]}${p[2]}${p[3]} ${p[4]}${p[5]}${p[6]}`;

  return { isValid: result != null, postcode: result };
};
